import * as React from 'react';
export const useRadioState = ({ state: initialState = false, onChange } = {}) => {
    const [state, setState] = React.useState(initialState);
    return {
        state,
        setState,
        onChange: React.useCallback((e) => {
            const { value } = e.currentTarget;
            setState(current => {
                if (value !== '') {
                    return value;
                }
                return !current;
            });
            if (typeof onChange === 'function') {
                onChange(e);
            }
        }, [onChange]),
    };
};
